.icons {
    display: flex;
    flex-direction: row;
}

.icons > * {
    transition: .2s;
}

.icons > *:hover {
    transform: scale(1.1);
}

.facebook {
    position: relative;
    width: fit-content;
}

.facebook::before {
    content: "";
    width: 17px;
    height: 17px;
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.instagram {
    position: relative;
    width: fit-content;
}

.instagram::before {
    content: "";
    width: 17px;
    height: 17px;
    position: absolute;
    background-image: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.whatsapp {
    position: relative;
    width: fit-content;
}

.whatsapp::before {
    content: "";
    width: 17px;
    height: 17px;
    position: absolute;
    background-color: #25d366;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.whatsapp::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    bottom: 4px;
    left: 4px;
    background-color: #25d366;
    z-index: -1;
}

.youtube {
    position: relative;
    width: fit-content;
}

.youtube::before {
    content: "";
    width: 17px;
    height: 10px;
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}